<div class="brand-ppheader">
  <div class="brand-ppheader__wrapper">
    <div class="brand-ppheader__row">
      <div class="brand-ppheader__page-name" [routerLink]="['/view']"
        routerLinkActive="router-link-active">
      <img src="../../../../assets/images/img/ieelogowhite.webp" alt="">
      </div>
      <div class="brand-ppheader__right-content">
        <div class="imgComp" (click)="viewProfile(true,userSession.id)">
          <img 
          src="{{userSession?.pictureFileLocations}}" alt="image"
          >
        </div>
        <div class="signOut" (click)="signOut()">sign out</div>
        <div class="searchfiled">
          <div class="brand-ppinput brand-ppinput--search-right">
            <div class="brand-ppinput__search-icon">
              <em class="brand-icon brand-icon-Search"></em>
            </div>
            <input type="text" class="brand-ppinput--search-right mb-3" placeholder="Search..." />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>