import { Component } from '@angular/core';

@Component({
  selector: 'app-brand-table',
  templateUrl: './brand-table.component.html',
  styleUrls: ['./brand-table.component.scss'],
})
export class BrandTableComponent {
  datanotFound:boolean = false;
  dataTable:boolean = true;

  tableHeadings = [
    'User',
    'Mob No.',
    'Age',
    'Status',
    'Description',
    'Edit',
  ];

  // tableKeys = [
  //   {
  //     category: 'Category type',
  //   },
  // ];

}
