import { Component } from '@angular/core';

@Component({
  selector: 'app-brand-ppaccordion',
  templateUrl: './brand-accordion.component.html',
  styleUrls: ['./brand-accordion.component.scss'],
})
export class BrandAccordionComponent {
  step = 0;
  percentagevalue: number = 0;
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  returnRound(num: number) {
    return Math.trunc(num);
  }


}
