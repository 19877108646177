<div class="brand-tabs-wrapper">
  <mat-tab-group class="brand-tabs">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="brand-tabs__title">Draft</span>
        <span class="brand-tabs__count">20</span>
      </ng-template>
      <div>
      Table
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="brand-tabs__title">Pending</span>
        <span class="brand-tabs__count">20</span>
      </ng-template>
      <div>Content 2</div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="brand-tabs__title">Rejected</span>
        <span class="brand-tabs__count">20</span>
      </ng-template>
      <div>Content 3</div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="brand-tabs__title">Completed</span>
        <span class="brand-tabs__count">20</span>
      </ng-template>
      <div>Content 3</div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="brand-tabs__title">Setteld</span>
        <span class="brand-tabs__count">20</span>
      </ng-template>
      <div>Content 3</div>
    </mat-tab>
  </mat-tab-group>

  <div class="brand-tabs-wrapper__button-right">
    <app-brand-button [btnLabel]="'Add work expense'" (click)="adddWorkexp()" [rmpBtnIconLeft]="true" [btnClass]="'brand-btn--primary'" [btnIconClass]="'brand-icon-icon_add'"></app-brand-button>
  </div>

</div>
