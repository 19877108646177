export enum ApiEndPoints {
  signIn = 'auth/signIn',
  //login
  signUp = 'auth/signUp',
  checkAuth = 'auth/checkAuth',

  profileGet = 'profile/get',
  profileEdit = 'profile/update',
  profileFind = 'profile/find',
  profileVisible = 'profile/visible',

  profileRecordCreate = 'profile/record/create',
  profileRecordUpdate = 'profile/record/update',
  profileRecordDelete = 'profile/record/delete',


  projectGet = 'project/find',
  projectView = 'project/get',
  projectCReate = 'project/create',
  projectUpdate = 'project/update',

  waitingList = 'auth/get/waitingList',
  milestoneConfigCreate = 'milestone/config/create',
  milestoneConfigFind = 'milestone/config/find',
  milestoneConfigGet = 'milestone/config/get',

  milestoneGet = 'milestone/get',
  milestoneFind = 'milestone/find',

  milestoneCustomCreate = 'milestone/customCreate',
  milestoneCustomGet = 'milestone/getConfig',

  emailSignUP = 'auth/email/signUp',
  emailSignIn = 'auth/email/signIn',

  adminCreate = 'admin/create/theaterOrdistributor',
}

export const sidebarList = [
  {
    title: 'Manage Users',
    icon: '/assets/images/svg/home.svg',
    url: '/main/user/management',
  },
  {
    title: 'Manage Config',
    icon: '/assets/images/svg/event.svg',
    url: '/main/user/manage-confiq',
  },

  // {
  //   title: 'Person',
  //   icon: '/assets/images/svg/person.svg',
  //   url: '/main/admin-landing',
  // },
];
