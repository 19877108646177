import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-brand-ppinput-dropdown',
  templateUrl: './brand-input-dropdown.component.html',
  styleUrls: ['./brand-input-dropdown.component.scss']
})
export class BrandInputDropdownComponent {

  // Label Filed
  @Input() labelField : boolean = true;
  selectedCity: any;
  cities = [
    {id: 1, name: 'Vilnius'},
    {id: 2, name: 'Kaunas'},
    {id: 3, name: 'Pavilnys', disabled: true},
    {id: 4, name: 'Pabradė'},
    {id: 5, name: 'Klaipėda'}
];

}
