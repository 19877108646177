import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-brand-modal-basic',
  templateUrl: './brand-modal-basic.component.html',
  styleUrls: ['./brand-modal-basic.component.scss'],
})
export class BrandModalBasicComponent {

  // input header show settings
  @Input() hideHeader: boolean = false;

  // inputting footer show setting
  @Input() hideFooter: boolean = false;

  // inputting body show setting
  @Input() hideBody: boolean = false;
}
