import { Component } from '@angular/core';

@Component({
  selector: 'app-brand-breadcrumb',
  templateUrl: './brand-breadcrumb.component.html',
  styleUrls: ['./brand-breadcrumb.component.scss']
})
export class BrandBreadcrumbComponent {

}
