import { Component } from '@angular/core';

@Component({
  selector: 'app-brand-card-wrap-header-option',
  templateUrl: './brand-card-header-option.component.html',
  styleUrls: ['./brand-card-header-option.component.scss']
})
export class BrandCardHeaderOptionComponent {

}
