
<nav class="brand-table-pagination"  aria-label="Pagination">
  <div class="brand-table-pagination__left-sec">
    <div class="brand-table-pagination__txt"> Rows per page </div>
    <ng-select  [(ngModel)]="pageLimit"  class="page-ngselect" >
      <ng-option *ngFor="let item of pageLimits">{{item}}</ng-option>
    </ng-select>
  </div>
  <div class="brand-table-pagination__right-sec">
    <a   class="tag-a" [ngClass]="{'disable':initialPage==1}">
      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
        aria-hidden="true">
        <path fill-rule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clip-rule="evenodd" />
      </svg>

    </a>

    <a  *ngFor="let item of pageArr;let i=index"
        [ngClass]="{ 'selected' :item.selected }" class="number-a">
          {{item ? item.page : '...'  }}
        </a>

    <a  class="tag-a last" [ngClass]="{'disable':initialPage==10}">


      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
        aria-hidden="true">
        <path fill-rule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clip-rule="evenodd" />
      </svg>
    </a>
  </div>

</nav>
