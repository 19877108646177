import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-brand-input-date',
  templateUrl: './brand-input-date.component.html',
  styleUrls: ['./brand-input-date.component.scss'],
})
export class BrandInputDateComponent {
  @ViewChild('textInput') textInput!: ElementRef;
  @ViewChild('dateInput') dateInput!: ElementRef;

  firstClick = true;

  // Form Label
  @Input() formLabel!: string;

  focusDateInput() {
    if (this.firstClick) {
      this.dateInput.nativeElement.focus();
      this.firstClick = false;
    }
  }
}
