<div class="brand-alert-modal">
  <div class="brand-alert-modal__wrapper">
    <div class="brand-alert-modal__close">
      <em class="brand-icons brand-icon-close-1"></em>
    </div>
    <div class="brand-alert-modal__icon pending"> <em class="brand-icons brand-icon-approved"></em> </div>
    <div class="brand-title__semi-bold">Your request is  successfully saved as draft!</div>
    <div class="brand-alert-modal__action"> <button class="me-3 brand-btn brand-btn--secondary  mt-4">Close</button> </div>
  </div>
</div>
