import { Component } from '@angular/core';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
})
export class ProfileListComponent {
  projects: any;
  constructor(private apiHelper: ApiHelper) {}

  ngOnInit(): void {
    this.getProjects();
  }

  getProjects() {
    this.apiHelper
      .post({}, ApiEndPoints.profileFind, true)
      .subscribe((response) => {
        this.projects = response.data;
      });
  }
}
