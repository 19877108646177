<app-brand-ppfilter-sort></app-brand-ppfilter-sort>
<app-brand-card-wrap-header-option></app-brand-card-wrap-header-option>

<div class="brand-card-wrap">
  <table>

    <tr *ngFor="let item of tableData">
      <td>
        <div class="brand-card-wrap__td-div">
          <div class="brand-card-wrap__td-row">
            <div class="brand-ppperson">
              <img class="person" src="{{item.person}}" alt="" />
            </div>
            <div class="brand-card-wrap__content">
              <div class="brand-title__semi-bold">{{item.name}} </div>
              <div class="brand-title__thin"> {{item.designation}} </div>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div class="brand-card-wrap__td-div">
          <div class="brand-card-wrap__td-row">
            <div class="brand-card-wrap__icon">
              <img class="icon" src="assets/images/svg/word-d0c.svg" alt="" />
            </div>
            <div class="brand-card-wrap__content">
              <div class="brand-title__thin">{{item.projectname}}  </div>
              <div class="brand-title__semi-bold">{{item.project}}</div>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div class="brand-card-wrap__td-div">
          <div class="brand-card-wrap__td-row">
            <div class="brand-card-wrap__icon">
              <img class="icon" src="assets/images/svg/word-d0c.svg" alt="" />
            </div>
            <div class="brand-card-wrap__content">
              <div class="brand-title__thin">{{item.owner}}</div>
              <div class="brand-title__semi-bold">{{item.leadName}} </div>
            </div>
          </div>
        </div>
      </td>

      <td>
        <div class="brand-card-wrap__td-div">
          <div class="brand-card-wrap__td-row">
            <div class="brand-card-wrap__icon">
              <img class="icon" src="assets/images/svg/word-d0c.svg" alt="" />
            </div>
            <div class="brand-card-wrap__content">
              <div class="brand-title__thin"> {{item.reqDate}}  </div>
              <div class="brand-title__semi-bold">{{item.date}}</div>
            </div>
          </div>
        </div>
      </td>

      <td>
        <div class="brand-card-wrap__td-div">
          <div class="brand-card-wrap__td-row">
            <app-brand-ppstatus></app-brand-ppstatus>
          </div>
        </div>
      </td>

      <td class="brand-card-wrap-menu">
        <div class="brand-card-wrap__td-div">
          <div class="brand-card-wrap__td-row">
            <div class="brand-card-wrap-action">
              <div class="brand-card-wrap-action__icon" [matMenuTriggerFor]="menu">
                <em class="brand-icon brand-icon-menu_select"></em>
              </div>
              <mat-menu #menu="matMenu" class="brand-card-wrap-action__menu">
                <div class="menu-item"> <em class="brand-icon brand-icon-duplicate"></em>  Duplicate </div>
                <div class="menu-item"> <em class="brand-icon brand-icon-close"></em>  Cancel Request </div>
              </mat-menu>
            </div>
          </div>
        </div>
      </td>
    </tr>

  </table>
</div>
