<ng-template #modal let-modal>

  <div class="brand-modal">

    <div *ngIf="!hideHeader" class="brand-modal__header">
      <ng-content select=".app-modal-header"></ng-content>
    </div>

    <div *ngIf="!hideBody" class="brand-modal__body ">
      <ng-content select=".app-modal-body"></ng-content>
    </div>

    <div *ngIf="!hideFooter" class="brand-modal__footer flex-wrap">
      <ng-content select=".app-modal-footer"></ng-content>
    </div>

  </div>

</ng-template>

