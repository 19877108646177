<div class="smallHeader">
    <div class="container-lg">
        <!-- <div class="row p-1 p-md-5 align-items-center rounded" style="background-image:url(https://images.pexels.com/photos/7130560/pexels-photo-7130560.jpeg);background-size:cover"> -->
        <div class="row py-3 py-md-4 px-2 px-md-4 align-items-center rounded"
            style="background-image:url(../../../../assets/images/pexels-photo.jpg);background-size:cover">
            <div class="col-md-6">
                <h1>Profile View</h1>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nemo pariatur quam ducimus nam consectetur
                    explicabo velit voluptas eos. Esse libero assumenda excepturi eveniet hic commodi enim veniam
                    tenetur
                    expedita! Officiis!</p>
                <div class="d-flex flex-wrap gap-2 mb-3 mb-md-0">
                    <a href="" class="btn btn-dark">Invest on Project</a>
                    <a href="" class="btn btn-outline-dark" [routerLink]="['project/create']">Create Crowdfunding
                        Campaign</a>
                </div>
            </div>
            <div class="col-md-6 text-center">
                <img src="assets/images/free-photo-of-orange-lens-in-the-dark.jpg" class="img-fluid rounded-circle w-50"
                    style="max-width: 250px;" alt="">
            </div>
        </div>
    </div>
</div>

<div class="container-lg mt-5">
    <div class="projects">
        <h1>Profile List</h1>
    </div>
    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 col-xl-3 mb-3" *ngFor="let item of projects">
            <div class="card bg-ff border border-dark rounded p-3 featured-project-list">
                <div class="d-flex justify-content-between align-items-center mb-3">
                </div>
                <div class="project-pic">
                    <img class="rounded img-fluid" [src]="item.pictureFileLocations"
                        onerror="this.onerror=null;this.src='assets/images/img/camera-set.jpg';" alt="Card image cap">
                </div>
                <p class="mb-1"><strong>Name :</strong> {{ item.name }}</p>
                <p><strong>gender :</strong> {{ item.gender }}</p>
                <a class="btn btn-grad-ff mt-2" [routerLink]="['profile/view', item.id]">View More</a>
            </div>
        </div>
    </div>
</div>