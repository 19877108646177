<div class="brand-toust-msg brand-toust-msg--success">
  <div class="brand-toust-msg__wrapper w-100">
    <div class="brand-toust-msg__item w-100">
      <div class="d-flex w-100">
      <div class="brand-toust-msg__icon"> <img src="/assets/images/svg/success.svg" alt=""> </div>
      <div class="ms-3 w-100">
        <div class="brand-toust-msg__title-msg">Successfully submitted</div>
        <div class="brand-toust-msg__content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam
        </div>
      </div>
      <div class="brand-toust-msg__hide hide-btn">Hide</div>
      </div>


    </div>
  </div>
</div>
