import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {


  constructor(
    private router: Router,
    private apiHelper: ApiHelper,
    private toastrService: ToastrService,
  ) {

  }

  profile !: any;
  userSession !: any;

  ngOnInit(): void {
    let sessionInLocalStorage: any = localStorage.getItem('session')
    let session = JSON.parse(sessionInLocalStorage)
    this.getProfileRecord(session.profileId)
  }


  getProfileRecord(id: string) {
    this.apiHelper.post({ id: id }, ApiEndPoints.profileGet).subscribe((response) => {

      if(response.data == null){
        this.signOut()
        return
      }
      this.userSession = response.data
    },
      (error) => {
        this.toastrService.error(error.error.message);
        // You can show an error message to the user here
      })
  }

  signOut() {
    localStorage.removeItem('session')
    localStorage.removeItem('profile')
    localStorage.removeItem('currentUser')
    this.router.navigate(['/login'])

  }

  viewProfile(id: string) {

    this.router.navigate(['view/profile/view', id])

  }

}
