<div class="container-lg">
  <div class="card bg-ff border border-dark rounded p-4">
    <div class="brand-around-table" *ngIf="dataTable">
      <div class="brand-table">
        <table class="brand-table__wrapper">
          <thead class="brand-table__head-main">
            <tr>
              <th class="brand-table__head"> <input type="checkbox"> </th>
              <th class="brand-table__head" *ngFor="let head of tableHeadings">
                {{ head }}
              </th>
            </tr>
          </thead>
          <tbody class="brand-table__body">
            <tr class="brand-table__row">
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div><input type="checkbox"> </div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">Category Type</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">08-08-2023</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">Travel</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <!-- BADGE LABEL -->
                  <div class="brand-table-badge">
                    <div class="brand-table-badge__item rejected"> Rejected </div>
                  </div>
                  <!-- BADGE LABEL -->
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <!-- BADGE LABEL -->
                  <div class="brand-table-badge">
                    <div class="brand-table-badge__item completed"> Completed </div>
                  </div>
                  <!-- BADGE LABEL -->
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <!-- ICON LABEL -->
                  <div class="brand-icon-label">
                    <div class="brand-icon-label__icon"> <em class="brand-icon brand-icon-Clip"></em> </div>
                    <div class="brand-title__semi-bold">6</div>
                  </div>
                  <!-- ICON LABEL -->
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <!-- BADGE LABEL -->
                  <div class="brand-table-badge">
                    <div class="brand-table-badge__item pending"> Pending </div>
                  </div>
                  <!-- BADGE LABEL -->
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">View</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <!-- LINK LABEL -->
                  <div class="brand-link-label">
                    View
                  </div>
                  <!-- LINK LABEL -->

                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-table-wrap-action">
                    <div class="brand-table-wrap-action__icon" [matMenuTriggerFor]="menu">
                      <em class="brand-icon brand-icon-menu_select"></em>
                    </div>
                    <mat-menu #menu="matMenu" class="brand-table-wrap-action__menu">
                      <div class="menu-item"> <em class="brand-icon brand-icon-duplicate"></em> Duplicate </div>
                      <div class="menu-item"> <em class="brand-icon brand-icon-close"></em> Cancel Request </div>
                    </mat-menu>
                  </div>
                </div>
              </td>
            </tr>
            <!-- FIRST ROW -->
            <!-- SECOND ROW -->
            <tr class="brand-table__row">
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div><input type="checkbox"> </div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">Category Type</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">08-08-2023</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">Travel</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">INR ($)</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <!-- BADGE LABEL -->
                  <div class="brand-table-badge">
                    <div class="brand-table-badge__item hold"> Hold </div>
                  </div>
                  <!-- BADGE LABEL -->
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">

                  <!-- BADGE LABEL -->
                  <div class="brand-table-badge">
                    <div class="brand-table-badge__item completed"> completed </div>
                  </div>
                  <!-- BADGE LABEL -->

                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">6</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">Description is</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">View</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-table-wrap-action">
                    <div class="brand-table-wrap-action__icon" [matMenuTriggerFor]="menu">
                      <em class="brand-icon brand-icon-menu_select"></em>
                    </div>
                    <mat-menu #menu="matMenu" class="brand-table-wrap-action__menu">
                      <div class="menu-item"> <em class="brand-icon brand-icon-duplicate"></em> Duplicate </div>
                      <div class="menu-item"> <em class="brand-icon brand-icon-close"></em> Cancel Request </div>
                    </mat-menu>
                  </div>
                </div>
              </td>
            </tr>
            <!-- SECOND FINISHED  -->
            <!-- THIRD ROW -->
            <tr class="brand-table__row">
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div><input type="checkbox"> </div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">Category Type</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">08-08-2023</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">Travel</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">INR ($)</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">($) 2000</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">08-08-2023</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">6</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">Description is</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">View</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-table-wrap-action">
                    <div class="brand-table-wrap-action__icon" [matMenuTriggerFor]="menu">
                      <em class="brand-icon brand-icon-menu_select"></em>
                    </div>
                    <mat-menu #menu="matMenu" class="brand-table-wrap-action__menu">
                      <div class="menu-item"> <em class="brand-icon brand-icon-duplicate"></em> Duplicate </div>
                      <div class="menu-item"> <em class="brand-icon brand-icon-close"></em> Cancel Request </div>
                    </mat-menu>
                  </div>
                </div>
              </td>
            </tr>
            <!-- THIRD ROW FINISH-->
            <!-- FOURTH ROW FINISH-->
            <tr class="brand-table__row">
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div><input type="checkbox"> </div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">Category Type</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">08-08-2023</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">Travel</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">INR ($)</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">($) 2000</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">08-08-2023</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">6</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">Description is</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-title__semi-bold">View</div>
                </div>
              </td>
              <td class="brand-table__td-item">
                <div class="brand-table__item-inner">
                  <div class="brand-table-wrap-action">
                    <div class="brand-table-wrap-action__icon" [matMenuTriggerFor]="menu">
                      <em class="brand-icon brand-icon-menu_select"></em>
                    </div>
                    <mat-menu #menu="matMenu" class="brand-table-wrap-action__menu">
                      <div class="menu-item"> <em class="brand-icon brand-icon-duplicate"></em> Duplicate </div>
                      <div class="menu-item"> <em class="brand-icon brand-icon-close"></em> Cancel Request </div>
                    </mat-menu>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="">
        <app-brand-table-pagination></app-brand-table-pagination>
      </div>

    </div>

    <div class="brand-data-not-found" *ngIf="datanotFound">
      <div class="brand-data-not-found__image"> <img src="../../../../../assets/images/svg/datanot-found.svg" alt=""> </div>
      <div class="brand-data-not-found__content">Lorem ipsum dolor sit amet</div>
      <div class="brand-data-not-found__sub-content"> Sed ut perspiciatis unde omnis iste natus error sit </div>
    </div>
  </div>
</div>