import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-brand-form-input',
  templateUrl: './brand-form-input.component.html',
  styleUrls: ['./brand-form-input.component.scss'],
})
export class BrandFormInputComponent {
  // Form Label
  @Input() formLabel!: string;

  // Form Label Filed
  @Input() formLabelField: boolean = true;
}
