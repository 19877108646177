import { Component } from '@angular/core';
import { PageCount } from 'src/app/core/interface/common.interface';

@Component({
  selector: 'app-brand-table-pagination',
  templateUrl: './brand-table-pagination.component.html',
  styleUrls: ['./brand-table-pagination.component.scss'],
})
export class BrandTablePaginationComponent {
  mode = 1;
  pageArr: PageCount[] = [
    {
      page: 1,
      selected: true,
    },
    {
      page: 2,
      selected: false,
    },
    {
      page: 3,
      selected: false,
    },
  ];
  initialCount = 1;
  selectedValue!: number;
  initialPage = 1;
  pseudoI!: number;
  pageLimit: number = 10;
  pageLimits: number[] = [10, 25, 50, 100];
}
