import { Component, Input } from '@angular/core';
import { SlideData } from 'src/app/core/interface/slider.interface';

@Component({
  selector: 'app-brand-slider-one',
  templateUrl: './brand-slider-one.component.html',
  styleUrls: ['./brand-slider-one.component.scss'],
})
export class BrandSliderOneComponent {
  @Input() sliderData!: SlideData[];

  currentSlide = 0;

  onSlideClick(index: number) {
    if (this.currentSlide !== index) {
      this.currentSlide = index;
    }
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.sliderData.length - 1 : previous;
    // console.log('previous clicked, new current slide is: ', this.currentSlide);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.sliderData.length ? 0 : next;
    // console.log('next clicked, new current slide is: ', this.currentSlide);
  }
}
