<div class="full-width">
<div class="brand-ppbody">
  <!-- <app-sidebar></app-sidebar>
  <div class="brand-ppbody__wrapper" [ngClass]="{'full-width': sideBarMinimized || sideBartoggle }"> -->
    <!-- <app-header></app-header> -->
    <div class="gradient-bg"></div>
    <app-nav-bar class=" brand-ppbody__navbar"></app-nav-bar>
    <main class="brand-ppbody__main">
      <router-outlet></router-outlet>
    </main>
  </div>
<!-- </div> -->
</div>