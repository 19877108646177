import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullComponent } from './shared/layout/full/full.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AdminGaurd } from './core/guard/admin.guard';
import { LoginPageComponent } from './modules/login/pages/login-page/login-page.component';
import { CrowdFundingComponent } from './modules/home/crowd-funding/crowd-funding.component';
import { ProfileListComponent } from './modules/profile/profile-list/profile-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/view',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component : LoginPageComponent
  },
  {
    path: 'admin',
    component: FullComponent,
     canActivate:[AuthGuard ,AdminGaurd],
    children: [
      {
        path:'',
        loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: 'view',
    component: FullComponent,
    canActivate:[AuthGuard],
    children: [
      {
        path : '',
        component : ProfileListComponent
      },
      {
        path : 'projects',
        component : CrowdFundingComponent
      },
      {
        path:'',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path:'profile',
        loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
      },
    ],
  },
  {
    path: '**',
    component : LoginPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule {}
