import { Component } from '@angular/core';

@Component({
  selector: 'app-brand-ppfilter-sort',
  templateUrl: './brand-filter-sort.component.html',
  styleUrls: ['./brand-filter-sort.component.scss']
})
export class BrandFilterSortComponent {

}
