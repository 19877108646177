import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HeaderComponent } from './layout/header/header.component';
import { FullComponent } from './layout/full/full.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import { BrandCardHeaderOptionComponent } from './brand-widget/molecule/brand-card-header-option/brand-card-header-option.component';
import { BrandMenuDropdownComponent } from './brand-widget/atom/brand-menu-dropdown/brand-menu-dropdown.component';
import { BrandButtonComponent } from './brand-widget/atom/brand-button/brand-button.component';
import { BrandCardComponent } from './brand-widget/molecule/brand-card/brand-card.component';
import {MatDialogModule} from '@angular/material/dialog';
import { BrandStatusComponent } from './brand-widget/atom/brand-status/brand-status.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrandInputDropdownComponent } from './brand-widget/atom/brand-input-dropdown/brand-input-dropdown.component';
import { FormsModule } from '@angular/forms';
import { BrandBreadcrumbComponent } from './brand-widget/atom/brand-breadcrumb/brand-breadcrumb.component';
import { BrandFilterSortComponent } from './brand-widget/molecule/brand-filter-sort/brand-filter-sort.component';
import { BrandSearchComponent } from './brand-widget/atom/brand-search/brand-search.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { BrandAccordionComponent } from './brand-widget/molecule/brand-accordion/brand-accordion.component';
import { BrandCardWrapComponent } from './brand-widget/organism/brand-card-wrap/brand-card-wrap.component';
import { BrandTabComponent } from './brand-widget/molecule/brand-tab/brand-tab.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import { BrandTableComponent } from './brand-widget/organism/brand-table/brand-table.component';
import { BrandTablePaginationComponent } from './brand-widget/molecule/brand-table-pagination/brand-table-pagination.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { BrandModalBasicComponent } from './brand-widget/organism/brand-modal-basic/brand-modal-basic.component';
import { BrandFormInputComponent } from './brand-widget/atom/brand-form-input/brand-form-input.component';
import { BrandSliderOneComponent } from './brand-widget/molecule/brand-slider-one/brand-slider-one.component';
import { BrandInputDateComponent } from './brand-widget/atom/brand-input-date/brand-input-date.component';
import { BrandSliderTwoComponent } from './brand-widget/molecule/brand-slider-two/brand-slider-two.component';
import { BrandAlertComponent } from './brand-widget/molecule/brand-alert/brand-alert.component';
import { BrandToasterMsgComponent } from './brand-widget/atom/brand-toaster-msg/brand-toaster-msg.component';
import { BrandCardItemComponent } from './brand-widget/molecule/brand-card-item/brand-card-item.component';
import { ToastrModule } from 'ngx-toastr';
import { NavBarComponent } from './layout/nav-bar/nav-bar.component';

@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    FullComponent,
    BrandCardWrapComponent,
    BrandCardHeaderOptionComponent,
    BrandMenuDropdownComponent,
    BrandButtonComponent,
    BrandCardComponent,
    BrandStatusComponent,
    BrandInputDropdownComponent,
    BrandBreadcrumbComponent,
    BrandFilterSortComponent,
    BrandSearchComponent,
    BrandAccordionComponent,
    BrandTabComponent,
    BrandTableComponent,
    BrandTablePaginationComponent,
    BrandModalBasicComponent,
    BrandFormInputComponent,
    BrandSliderOneComponent,
    BrandInputDateComponent,
    BrandSliderTwoComponent,
    BrandAlertComponent,
    BrandToasterMsgComponent,
    BrandCardItemComponent,
    NavBarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    NgSelectModule,
    FormsModule,
    MatTabsModule,
    MatExpansionModule,
    MatTooltipModule,
    MatCheckboxModule,
    ToastrModule.forRoot(),
  ],
  exports: [
    BrandCardWrapComponent,
    BrandCardHeaderOptionComponent,
    BrandMenuDropdownComponent,
    BrandButtonComponent,
    BrandCardComponent,
    BrandStatusComponent,
    BrandInputDropdownComponent,
    BrandBreadcrumbComponent,
    BrandFilterSortComponent,
    BrandSearchComponent,
    BrandSliderTwoComponent,
    BrandAccordionComponent,
    BrandTabComponent,
    BrandTableComponent,
    BrandFormInputComponent,
    BrandInputDateComponent,
    BrandSliderOneComponent,
    BrandToasterMsgComponent,
    BrandCardItemComponent
  ]
})
export class SharedModule { }
