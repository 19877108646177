import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-brand-button',
  templateUrl: './brand-button.component.html',
  styleUrls: ['./brand-button.component.scss'],
})
export class BrandButtonComponent {
  // Input btn with icon
  @Input() rmpBtnIcon: boolean = false;

  // Input btn only
  @Input() rmpBtn: boolean = false;

    // Input btnIconLeft
    @Input() rmpBtnIconLeft : boolean = false;

  // Input btn class
  @Input() btnClass!: string;

  // Input btnLabel name
  @Input() btnLabel!: string;

  // Input btnIconClass
  @Input() btnIconClass!: string;



  // Emitting Btn Click
  @Output() btnClick: EventEmitter<MouseEvent> = new EventEmitter();

  btnClicked(event:MouseEvent) {
    this.btnClick.emit(event);
  }
}
