import { Component, Input } from '@angular/core';
import { SlideData } from 'src/app/core/interface/slider.interface';

@Component({
  selector: 'app-brand-slider-two',
  templateUrl: './brand-slider-two.component.html',
  styleUrls: ['./brand-slider-two.component.scss']
})
export class BrandSliderTwoComponent {
  @Input() sliderData!: SlideData[];
  sliderImage:string = '../../../../../assets/images/svg/no-image.svg';
  currentSlide = 0;

  onSlideClick(index: number , src:string) {

    // const currentIndex =  this.sliderData.indexOf(this.sliderImage);
    // for (let index = 0; index < this.sliderData.length; index++) {
    //   const element = this.sliderData[index];
    //   this.sliderImage = element.src;
    // }

    if (this.currentSlide !== index) {
      this.sliderImage = src;
      this.currentSlide = index;
    }
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.sliderData.length - 1 : previous;
    // console.log('previous clicked, new current slide is: ', this.currentSlide);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.sliderData.length ? 0 : next;
    // console.log('next clicked, new current slide is: ', this.currentSlide);
  }
}
