<button class="brand-ppmat-dropdown" [matMenuTriggerFor]="menu"  (click)="btnClicked($event)">
  <div class="brand-ppmat-dropdown__label">
    <ng-container *ngIf="rmpDropdownIcon">
    <div class="brand-ppmat-dropdown__icon-label">
      <span class="brand-ppmat-dropdown__menu-icon" >
        <em class="brand-icon" ngClass="{{iconClass}}"></em>
      </span>
      <span class="brand-title__md-label"> {{dropdownLabel}}</span>
    </div>
  </ng-container>

    <ng-container *ngIf="rmpDropdown">
    <div class="brand-ppmat-dropdown__icon-label">
      <img src="{{imgIcon}}" *ngIf="imgIconDiv" alt="">
      <span class="brand-title__md-label"> {{dropdownLabel}}</span>
    </div>
  </ng-container>
    <img src="{{imgIcon}}" *ngIf="imgIconDiv" alt="">
    <em class="brand-icon brand-icon-down_arrow"></em>
  </div>
</button>

<mat-menu #menu="matMenu" class="brand-ppmat-dropdown__menu">
  <div class="menu-item">menu 1</div>
  <div class="menu-item">menu 1</div>
  <div class="menu-item">menu 1</div>
  <div class="menu-item">menu 1</div>
</mat-menu>
