import { Component } from '@angular/core';

@Component({
  selector: 'app-brand-alert',
  templateUrl: './brand-alert.component.html',
  styleUrls: ['./brand-alert.component.scss']
})
export class BrandAlertComponent {

}
