import { Component } from '@angular/core';

@Component({
  selector: 'app-brand-card-wrap',
  templateUrl: './brand-card-wrap.component.html',
  styleUrls: ['./brand-card-wrap.component.scss'],
})
export class BrandCardWrapComponent {


  tableData = [
    {
      person: 'assets/images/svg/unknown-head.svg',
      name: 'Naveen Chacko',
      designation: 'UI/UX Designer ',
      projectname: 'Project Name',
      project: 'Sharaka',
      owner: 'Project Owner',
      leadName: 'Rupam Chirom',
      reqDate: 'Requested date',
      date: '14 May 2023 ',
    },
    {
      person: 'assets/images/svg/unknown-head.svg',
      name: 'Naveen Chacko',
      designation: 'UI/UX Designer ',
      projectname: 'Project Name',
      project: 'Sharaka',
      owner: 'Project Owner',
      leadName: 'Rupam Chirom',
      reqDate: 'Requested date',
      date: '14 May 2023 ',
    },
    {
      person: 'assets/images/svg/unknown-head.svg',
      name: 'Naveen Chacko',
      designation: 'UI/UX Designer ',
      projectname: 'Project Name',
      project: 'Sharaka',
      owner: 'Project Owner',
      leadName: 'Rupam Chirom',
      reqDate: 'Requested date',
      date: '14 May 2023 ',
    },
  ];

}
