<ng-container *ngIf="rmpBtnIcon">
  <button class="brand-btn " [ngClass]="btnClass" (click)="btnClicked($event)"> <span class="brand-label d-mobile-none">{{btnLabel}}</span> <em class="brand-icon" [ngClass]="btnIconClass"></em> </button>
</ng-container>
<ng-container *ngIf="rmpBtnIconLeft">
  <button class="brand-btn " [ngClass]="btnClass" (click)="btnClicked($event)"> <em class="brand-icon" [ngClass]="btnIconClass"></em>  <span class="brand-label d-mobile-none">{{btnLabel}}</span> </button>
</ng-container>

<ng-container *ngIf="rmpBtn">
  <button  class="brand-btn" [ngClass]="btnClass" (click)="btnClicked($event)"> <span class="brand-label">{{btnLabel}}</span> </button>
</ng-container>
