import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-brand-ppmenu-dropdown',
  templateUrl: './brand-menu-dropdown.component.html',
  styleUrls: ['./brand-menu-dropdown.component.scss'],
})
export class BrandMenuDropdownComponent {
  // Name with icon
  @Input() rmpDropdownIcon: boolean = false;

  // Nameonly
  @Input() rmpDropdown: boolean = false;

  // Dropdown btnLabel name
  @Input() dropdownLabel!: string;

  // Input btn class
  @Input() btnClass!: string;

  // Input btn class
  @Input() iconClass!: string;

  // Input btn class
  @Input() imgIcon!: string ;

  // Input btn class
  @Input() imgIconDiv: boolean = false ;

  // Emitting Btn Click
  @Output() btnClick: EventEmitter<MouseEvent> = new EventEmitter();

  btnClicked(event: MouseEvent) {
    this.btnClick.emit(event);
  }
}
