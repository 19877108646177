<div class="brand-slider-one pt-5">

  <div class="brand-slider-one__slider">
    <ul class="brand-slider-one__slider-wrapper" [style.transform] = "'translateX(-' + currentSlide * (100 / sliderData.length) + '%)'">

      <li *ngFor="let slide of sliderData"
          class="brand-slider-one__slide"
          [class.brand-slider-one__slide--current]="slide.index === currentSlide"
          [class.brand-slider-one__slide--next]="slide.index === currentSlide + 1"
          [class.brand-slider-one__slide--previous]="slide.index === currentSlide - 1"
          (click)="onSlideClick(slide.index)">
        <div class="brand-slider-one__slide-image-wrapper">
          <img class="brand-slider-one__slide-image" [src]="slide.src" [attr.alt]="slide.headline" style="opacity: 1;">
        </div>

        <div class="brand-slider-one__slide-actions">
          <div class="brand-slider-one__img-label">namenamenamenamename</div>

            <div class="d-flex">
              <div> <em class="brand-icons brand-icon-Download"></em> </div>
              <div class="ms-2"> <em class="brand-icons brand-icon-Delete-fill"></em> </div>
            </div>
        </div>

      </li>
    </ul>

  </div>

  <div class="brand-slider-one__slider-controls">
    <div class="btn btn--previous" title="Go to previous slide" (click)="onPreviousClick()">
      <svg class="icon" width="29px" viewBox="0 0 24 24">
        <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path>
      </svg>
    </div>
    <div class="btn btn--next" title="Go to next slide"  (click)="onNextClick()">
      <svg class="icon" width="29px" viewBox="0 0 24 24">
        <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path>
      </svg>
    </div>
  </div>

</div>
