<div>
  <mat-accordion class="brand-ppform-accordian">
    <mat-expansion-panel [expanded]="step === 0" [@.disabled]="true" [expanded]="step === 0" (opened)="setStep(0)" class="brand-ppform-accordian__mainpanel">
      <mat-expansion-panel-header class="brand-pprecent-reqst brand-ppform-accordian__main-header">
        <mat-panel-title>
          <div class="brand-pprecent-reqst__header-wrap">
            <div class="brand-pprecent-reqst__header-content">
              <div class="brand-pprecent-reqst__header-item">
                <div class="brand-pprecent-reqst__header-person">
                  <img src="assets/images/svg/unknown-head.svg" class="" alt="" />
                </div>
                <div class="brand-pprecent-reqst__header-person-detail">
                  <div class="brand-title__thin">Name</div>
                  <div class="brand-title__semi-bold">Sanjay</div>
                </div>
              </div>
            </div>

            <div class="brand-pprecent-reqst__header-content">
              <div class="brand-pprecent-reqst__header-item">
                <div class="brand-pprecent-reqst__header-person">
                  <img src="assets/images/svg/unknown-head.svg" class="" alt="" />
                </div>
                <div class="brand-pprecent-reqst__header-person-detail">
                  <div class="brand-title__thin">Manager</div>
                  <div class="brand-title__semi-bold">Sanjay</div>
                </div>
              </div>
            </div>

            <div class="brand-pprecent-reqst__header-content">
              <div class="brand-pprecent-reqst__header-item">
                <div class="brand-pprecent-reqst__header-person">
                  <em class="brand-icon brand-icon-User-Management"></em>
                </div>
                <div class="brand-pprecent-reqst__header-person-detail">
                  <div class="brand-title__thin">Requested By</div>
                  <div class="brand-title__semi-bold">Vinod</div>
                </div>
              </div>
            </div>

            <div class="brand-pprecent-reqst__header-content">
              <div class="brand-pprecent-reqst__header-item">
                <div class="brand-pprecent-reqst__header-person">
                  <em class="brand-icon brand-icon-requested-date"></em>
                </div>
                <div class="brand-pprecent-reqst__header-person-detail">
                  <div class="brand-title__thin">Requested date</div>
                  <div class="brand-title__semi-bold">14 May 2023</div>
                </div>
              </div>
            </div>

            <div class="brand-pprecent-reqst__header-content">
              <div class="brand-pprecent-reqst__header-item">
                <div class="brand-pprecent-reqst__header-person">
                  <em class="brand-icon brand-icon-Project-Name"></em>
                </div>
                <div class="brand-pprecent-reqst__header-person-detail">
                  <div class="brand-title__thin">Project Name</div>
                  <div class="brand-title__semi-bold">Sharaka</div>
                </div>
              </div>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="brand-ppform-accordian__subbody">
        <div class="brand-ppform-accordian__sub-form-body">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xl-6 pt-2">
              <div class="brand-ppdate-line">
                <div class="brand-ppdate-line__person-date-line me-5">
                  <div class="brand-ppdate-line__date-icon">
                    <em class="brand-icon brand-icon-start_date"></em>
                  </div>
                  <div class="d-flex me-4">
                    <div class="brand-title__thin me-2">Start Date</div>
                    <div class="brand-title__semi-bold">17 May 2023</div>
                  </div>
                </div>

                <div class="brand-ppdate-line__person-date">
                  <div class="brand-ppdate-line__date-icon">
                    <em class="brand-icon brand-icon-end_date"></em>
                  </div>
                  <div class="d-flex">
                    <div class="brand-title__thin me-2">End Date</div>
                    <div class="brand-title__semi-bold">17 May 2023</div>
                  </div>
                </div>
              </div>

              <div class="mt-4">
                <div class="brand-form-control">
                  <label class="brand-form-control__label"> Comments </label>
                  <textarea name="" id="" placeholder="loreum ipsum" cols="20" rows="6"></textarea>
                </div>
              </div>


            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xl-6 pt-2">
              <div class="brand-title__thin mb-2"> Projects running on these dates</div>

              <div class="d-flex flex-wrap">
                <div class="brand-pplabel me-3 mb-3">Sharaka</div>
                <div class="brand-pplabel me-3 mb-3">IPC</div>
                <div class="brand-pplabel me-3 mb-3">IPC</div>
                <div class="brand-pplabel me-3 mb-3">IPC</div>
                <div class="brand-pplabel me-3 mb-3">IPC</div>
                <div class="brand-pplabel brand-pplabel--more me-3 mb-3"> +2 </div>
              </div>

              <div class="check-available"> Check Availability <span> <em class="brand-icon brand-icon-back_button"></em> </span> </div>

            </div>
          </div>

          <div class="d-flex mt-4">
            <button class="me-3 brand-btn brand-btn--secondary">Reject</button>
            <button class="me-3 brand-btn brand-btn--primary">Approve</button>
          </div>

        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
