<div class="brand-card-item__card">
  <div class="brand-card-item__card-body">
    <div class="brand-card-item__card-head mb-3">
      <div class="brand-card-item__card-icon">
        <em ngClass="{{iconColor}}" class="brand-icons"></em>
      </div>
      <div class="brand-card-item__card-month" *ngIf="cardDropdownMenu">
        <app-brand-ppmenu-dropdown [dropdownLabel]="'Monthly'" [rmpDropdown]="true"></app-brand-ppmenu-dropdown>
      </div>
    </div>
    <div class="brand-card-item__card-status-label">
      {{cardLabel}}
    </div>
    <div class="brand-card-item__card-count-status mt-3">
      <div class="brand-card-item__card-count">10</div>
      <div class="brand-card-item__card-status" *ngIf="cardStatus">
        <div class="brand-card-item__card-status-badge">
          <em class="brand-icons brand-icon-open-link-in-window">
          </em>
          <span> + 3%</span>
        </div>
        <div class="brand-card-item__card-status-month">
          than last month
        </div>
      </div>
    </div>
  </div>
</div>
