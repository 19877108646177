import { Component, OnInit } from '@angular/core';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-crowd-funding',
  templateUrl: './crowd-funding.component.html',
  styleUrls: ['./crowd-funding.component.scss']
})
export class CrowdFundingComponent implements OnInit{
  
  
  projects :any;
  constructor(
    private apiHelper : ApiHelper,
  ){

  }

  ngOnInit(): void {
    this.getProjects()
  }

  
  getProjects() {
    this.apiHelper.post( {} ,ApiEndPoints.projectGet ,true).subscribe((response)=>{
      this.projects = response.data
    })
    
  }
  
}
