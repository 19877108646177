<header id="header">
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container-lg">
      <div class="logo">
        <img src="../../../../assets/images/img/ieelogowhite.webp" alt="" [routerLink]="['/view']">
      </div>
      <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse d-flex justify-content-between" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item active">
            <a class="nav-link" href="#">Crowd Funding </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">FF Exclusive</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Exclusive</a>
          </li>
        </ul>
        <form class="form-inline my-2 my-lg-0 d-flex">
          <input class="form-control-ff mr-sm-2" type="search" placeholder="Search" aria-label="Search">
          <div class="imgComp mx-2 d-flex align-items-center justify-content-center" (click)="viewProfile(userSession.id)">
            <img class="img-fluid" src="{{userSession?.pictureFileLocations}}" alt="image" onerror="this.src ='assets/images/svg/defaultProfilePice.svg' ">
          </div>
          <!-- <button class="btn btn-primary" [routerLink]="['/admin']"  >admin</button> -->
        </form>
      </div>
    </div>
  </nav>
</header>

<!-- 
<div class="navbar">
  <div class="navdiv">
    <div class="content_left">
     
      <img
        src="../../../../assets/images/img/ieelogowhite.webp"
        alt=""
        [routerLink]="['/view']"
      />
    
      <h1>Crowd Funding</h1>
      <h1>FF Exclusive</h1>
      <h1>Exclusive</h1>
    </div>
    <div class="content_right">
      <div class="searchfiled">
        <div class="brand-ppinput brand-ppinput--search-right">
          <div class="brand-ppinput__search-icon">
            <em class="brand-icon brand-icon-Search"></em>
          </div>
          <input
            type="text"
            class="brand-ppinput--search-right mb-3"
            placeholder="Search..."
          />
        </div>
      </div>
      <div class="imgComp" (click)="viewProfile(userSession.id)">
        <img
          src="{{ userSession?.pictureFileLocations }}"
          alt="image"
          onerror="this.src ='../../../../assets/images/svg/profile 2.svg' "
        />
      </div>
    </div>
  </div>
</div> -->