<div class="smallHeader">
  <div class="container-lg">
    <!-- <div class="row p-1 p-md-5 align-items-center rounded" style="background-image:url(https://images.pexels.com/photos/7130560/pexels-photo-7130560.jpeg);background-size:cover"> -->
    <div class="row py-3 py-md-4 px-2 px-md-4 align-items-center rounded" style="background-image:url(../../../../assets/images/pexels-photo.jpg);background-size:cover">
      <div class="col-md-6">
        <h1>Invest And Create</h1>
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nemo pariatur quam ducimus nam consectetur
          explicabo velit voluptas eos. Esse libero assumenda excepturi eveniet hic commodi enim veniam tenetur
          expedita! Officiis!</p>
        <div class="d-flex flex-wrap gap-2 mb-3 mb-md-0">
          <a href="" class="btn btn-dark">Invest on Project</a>
          <a href="" class="btn btn-outline-dark" [routerLink]="['project/create']">Create Crowdfunding Campaign</a>
        </div>
      </div>
      <div class="col-md-6 text-center">
        <img src="../../../../assets/images/free-photo-of-orange-lens-in-the-dark.jpg" class="img-fluid rounded-circle w-50" style="max-width: 250px;" alt="">
        <!-- <img src="https://images.pexels.com/photos/18662650/pexels-photo-18662650/free-photo-of-orange-lens-in-the-dark.png" class="img-fluid rounded-circle w-50" style="max-width: 250px;" alt=""> -->
      </div>
    </div>
    <!-- <img src='../../../../assets/images/svg/cbg.svg' class='smallHeader-image'> -->
  </div>
</div>


<div class="container-lg mt-5">
  <div class="projects">
    <h1>Featured projects</h1>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-12 col-xl-3 mb-3" *ngFor="let item of projects">
      <div class="card bg-ff border border-dark rounded p-3 featured-project-list">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="profile-pic-sm">
            <img class="img-fluid rounded-circle float-start" src="../../../../assets/images/user1.png" alt="Profile Image" />
            <div class="float-start ms-2 mt-1">
              <span class="text-capitalize text-white"> {{ item.userInfo.name }}</span>
            </div>
          </div>
          <div class="float-end">
            <p class="m-0 text-secondary">{{ item.userInfo.projectCount }} succesfull projects</p>
          </div>
        </div>
        <div class="project-pic">
          <img class="rounded img-fluid" src="../../../../assets/images/project-pic1.png" alt="Card image cap">
        </div>
        <p class="mb-1"><strong>Movie Name :</strong> {{ item.name }}</p>
        <p><strong>Movie Description :</strong> {{ item.description }}</p>
        <a class="btn btn-grad-ff mt-2" [routerLink]="['/view/details', item.id]">View More</a>
      </div>
    </div>


    <!-- <div class="col-lg-3 col-sm-12 col-md-4 mb-3" *ngFor="let item of projects">
      <div class="card"> 

        <img class="img-fluid" src="../../../../assets/images/img/cameraShotjpg" alt="Profile Image" />
        <img class="card-img-top img-fluid" src="{{ item.pictureFileLocations }}" alt="Card image cap">
        <div class="card-body">
          <h3 class="card-title text-capitalize fw-bold mb-2"> {{ item.userInfo.name }}</h3>
          <h5 class="mb-1"><strong>Movie Name :</strong> {{ item.name }}</h5>
          <h5><strong>Movie Description :</strong> {{ item.description }}</h5>
          <p class="card-text">
          <p class="my-3">{{ item.userInfo.projectCount }} succesfull projects</p>
          <a href="#" class="btn btn-grad-ff" [routerLink]="['/view/details', item.id]">Go somewhere</a>
        </div>
      </div>
    </div> -->
  </div>


  <!-- <div class="project-view" *ngFor="let item of projects">
        <div class="project" [routerLink]="['/view/details', item.id]">
          <div class="profile-info">
            <div class="profile-image-container">
              <div class="profile-image">
                <img
                  class="img-fluid"
                  src="../../../../assets/images/img/cameraShot.jpg"
                  alt="Profile Image"
                />
              </div>
              <div class="profile-name">
                <h3>
                  {{ item.userInfo.name }}
                </h3>
              </div>
            </div>
            <div class="profile-description">
              <p>{{ item.userInfo.projectCount }} succesfull projects</p>
            </div>
          </div>
          <div class="project-info">
            <div class="project-main-image">
              <img src="{{ item.pictureFileLocations }}" alt="project image" />
            </div>
            <div class="project-data">
              <h3>Movie Name : {{ item.movieName }}</h3>
              <h3>Movie Description : {{ item.movieDescription }}</h3>
              <div class="invest">
                
              </div>
            </div>
          </div>
        </div>
      </div> -->
</div>