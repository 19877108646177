import { Component } from '@angular/core';

@Component({
  selector: 'app-brand-toaster-msg',
  templateUrl: './brand-toaster-msg.component.html',
  styleUrls: ['./brand-toaster-msg.component.scss']
})
export class BrandToasterMsgComponent {

}
