import { Component } from '@angular/core';

@Component({
  selector: 'app-brand-ppstatus',
  templateUrl: './brand-status.component.html',
  styleUrls: ['./brand-status.component.scss']
})
export class BrandStatusComponent {

}
