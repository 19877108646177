import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-brand-ppsearch',
  templateUrl: './brand-search.component.html',
  styleUrls: ['./brand-search.component.scss'],
})
export class BrandSearchComponent {

  @Input() mode: 'default' | 'disabled' = 'default';

  @Input() search: 'search-right' | 'search-left' = 'search-right';

  public get rightSearch(): string[] {
    return ['brand-ppinput', `brand-ppinput--${this.search}`];
  }

  public get classes(): string[] {
    return ['brand-ppinput', `brand-ppinput--${this.mode}`];
  }

}
