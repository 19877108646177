<div class="brand-slider-two">
  <div class="brand-slider-two__two-slide">
    <div class="brand-slider-two__two-slide-single">
      <img [src]="sliderImage" width="w-100" alt="">
    </div>

    <div class="brand-slider-two__slide-actions">
      <div class="brand-slider-two__img-label">namenamenamenamename</div>
      <div class="d-flex">
        <div> <em class="brand-icons brand-icon-Download"></em> </div>
        <div class="ms-2"> <em class="brand-icons brand-icon-Delete-fill"></em> </div>
      </div>
    </div>

    <div class="brand-slider-two__two-small-items">
      <div class="brand-slider-two__slider">
        <ul class="brand-slider-two__slider-wrapper" [style.transform] = "'translateX(-' + currentSlide * (100 / sliderData.length) + '%)'">

          <li *ngFor="let slide of sliderData"
              class="brand-slider-two__slide"
              [class.brand-slider-two__slide--current]="slide.index === currentSlide"
              [class.brand-slider-two__slide--next]="slide.index === currentSlide + 1"
              [class.brand-slider-two__slide--previous]="slide.index === currentSlide - 1"
              (click)="onSlideClick(slide.index, slide.src)">
            <div class="brand-slider-two__slide-image-wrapper">
              <img class="brand-slider-two__slide-image" [src]="slide.src" [attr.alt]="slide.headline" style="opacity: 1;">
            </div>



          </li>
        </ul>

      </div>

      <div class="brand-slider-two__slider-controls">
        <div class="btn btn--previous"title="Go to previous slide" (click)="onPreviousClick()">
          <svg class="icon"  fill="#fff"  viewBox="0 0 24 24">
            <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path>
          </svg>
        </div>
        <div class="btn btn--next" title="Go to next slide"  (click)="onNextClick()">
          <svg class="icon" fill="#fff" viewBox="0 0 24 24">
            <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path>
          </svg>
        </div>
      </div>
    </div>

  </div>



</div>
