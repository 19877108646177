import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-brand-card-item',
  templateUrl: './brand-card-item.component.html',
  styleUrls: ['./brand-card-item.component.scss'],
})
export class BrandCardItemComponent {

  // CARD ICON COLOR
  @Input() iconColor!: string;

  // CARD LABEL
  @Input() cardLabel!: string;

  // CARD LABEL
  @Input() cardDropdownMenu: boolean = false;

  // CARD STATUS
  @Input() cardStatus: boolean = false;
}
