import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-brand-tab',
  templateUrl: './brand-tab.component.html',
  styleUrls: ['./brand-tab.component.scss'],
})
export class BrandTabComponent {
  constructor(private dialog: MatDialog) {}
  adddWorkexp() {
  }
}
